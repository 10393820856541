import React, { useState, useEffect } from "react"
import CartProductItem from "./cart-product-item"
import Button from "../button/button"
import style from "./cart-item.mod.scss"

const CartItem = ({ handleGetCart, winery_name, winery_id, order_total, minimum_wines_per_order, winery_bottle_count, item }) => {
  const cartProductItems = item

  
  //  console.log("cartProductItems" , cartProductItems)

  const [isMinimumWinesPerOrderMet, setIsMinimumWinesPerOrderMet] = useState(false)

  const HandleIsMinimumWinesPerOrderMet = () => {
    // console.log("HandleIsMinimumWinesPerOrderMet", winery_bottle_count, minimum_wines_per_order)
    if(winery_bottle_count >= minimum_wines_per_order){
      setIsMinimumWinesPerOrderMet(true)
    }else{
      setIsMinimumWinesPerOrderMet(false)
    }
  }

  useEffect(() => {
    HandleIsMinimumWinesPerOrderMet()
  }, [handleGetCart])

  return (
    <div className={style.item}>
      <h4>{winery_name}</h4>
      <div className={style.item__header}>
        <h5>{"Product"}</h5>
        <h5>{"Code"}</h5>
        <h5>{"Price"}</h5>
        <h5>{"Total"}</h5>
      </div>
      {cartProductItems.map((item) => (
        <CartProductItem key={item.wine_id}
          handleGetCart={handleGetCart}
          {...item}
        />
      ))}
      <div className={style.item__lower}>
        <p>
          Total: <span className={style.item__total}>${order_total.toFixed(2)}</span>
        </p>
        {isMinimumWinesPerOrderMet &&
        <Button primary customPath={`/checkout-auth/`} state={{ winery_id: winery_id }}>
          {"Go to checkout"}
        </Button>
        }
      </div>
      {isMinimumWinesPerOrderMet ? ( 
        <small className={style.item__min}>Minimum wines for this winery has been met.</small>
      ) : (
        <small className={style.item__min}>You must purchase {minimum_wines_per_order} bottles of any wines from this winery to checkout.</small>
      )}      
    </div>
  )
}

export default CartItem
