import React, { useState, useEffect } from "react"
import { getUser } from "../../services/auth"
import { removeCartItem, updateCartItemQuantity } from "../../services/cart"
import QuantitySelector from "../button/quantity-selector"
import style from "./cart-product-item.mod.scss"
import demoImage from "../../assets/images/demoProduct.png"
import Bin from "../icons/bin"
import { toast } from "react-toastify"
import { eventRemoveFromCart } from "../../services/gtm-events"

const CartProductItem = ({
  handleGetCart,
  name,
  price,
  selected_quantity,
  type,
  image,
  wine_id,
  winery_name,
}) => {
  const [quantity, setQuantity] = useState(selected_quantity)
  const [firstRender, setFirstRender] = useState(true)

  const handleImgError = (e) => {
    e.target.src = demoImage
  }

  // useEffect(() => {
  //   setQuantity(selected_quantity)
  // }, [selected_quantity])

  const handleQuantityChange = (counter) => {
    if (firstRender === true) {
      setFirstRender(false)
      return
    }

    if (counter == 0) {
      handleRemoveCartItem()
    } else {
      const user = getUser()
      const updateCartItemQuantityData = {
        user: user,
        id: wine_id,
        type: type,
        quantity: counter,
      }
      updateCartItemQuantity(updateCartItemQuantityData).then((res) => {
        if (res.status) {
          if (res.message == "Added to Cart") {
            toast.success("Cart item quantity updated")
          } else {
            toast.success(res.message)
          }
        } else {
          toast.error(res.message)
        }
        handleGetCart()
      })
    }
  }

  useEffect(() => {
    handleQuantityChange(quantity)
  }, [quantity])

  const handleRemoveCartItem = () => {
    const user = getUser()
    const removeCartItemData = {
      user: user,
      id: wine_id,
      type: type,
    }
    removeCartItem(removeCartItemData).then((res) => {
      if (res.status) {
        toast.success(res.message)
        const gtmData = {
          id: wine_id,
          name: name,
          price: price,
          quantity: quantity == 0 ? 1 : quantity,
          brand: winery_name,
        }
        eventRemoveFromCart(gtmData)
      } else {
        toast.error(res.message)
      }
      handleGetCart()
    })
  }

  const total = price * quantity

  return (
    <div className={style.product}>
      <div className={style.product__details}>
        <div>
          <img src={image} alt="" onError={handleImgError} />
        </div>
        <div>
          <h6>
            {name} <span className={style.product__code_m}>#{wine_id}</span>
          </h6>
          <QuantitySelector
            small
            quantity={quantity}
            setQuantity={(qty) => setQuantity(qty)}
          />
          <p className={style.product__price_m}>
            <b>${total.toFixed(2)}</b>
            <span>${price.toFixed(2)} / bottle</span>
          </p>
        </div>
      </div>
      <p className={style.product__code}>{wine_id}</p>
      <p className={style.product__price}>${price.toFixed(2)}</p>
      <div className={style.product__total}>
        <p>${total.toFixed(2)}</p>
        <div
          onClick={() => {
            handleRemoveCartItem()
          }}
        >
          <Bin />
        </div>
      </div>

      {/* <div>Product ID: {wine_id}</div>
      <div>Product Name: {name}</div>
      <div>Price: {price}</div>
      <div>Quantity: {quantity}</div>
      <div
        onClick={() => {
          handleRemoveCartItem()
        }}
      >
        Remove Item from cart
      </div>
      <QuantitySelector
        quantity={quantity}
        setQuantity={(qty) => setQuantity(qty)}
      /> */}
    </div>
  )
}

export default CartProductItem
