import React from "react"

const Bin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.75 22.5">
      <path
        d="M15.94 22.5H2.8a1.88 1.88 0 0 1-1.87-1.88V4.7H0V2.8h5.63v-1.4A1.4 1.4 0 0 1 7.03 0h4.69a1.4 1.4 0 0 1 1.4 1.4v1.41h5.63V4.7h-.94v15.93a1.88 1.88 0 0 1-1.87 1.88zm0-17.81H2.8v15.47a.47.47 0 0 0 .47.46h12.19a.47.47 0 0 0 .47-.46zM7.5 8.44a.94.94 0 0 0-1.88 0v8.44a.94.94 0 0 0 1.88 0zm5.63 0a.94.94 0 0 0-1.88 0v8.44a.94.94 0 0 0 1.88 0zm-1.88-6.56H7.5v.93h3.75z"
        fill="#60003a"
        fill-rule="evenodd"
      />
    </svg>
  )
}

export default Bin
