import React from "react"
import style from "./quantity-selector.mod.scss"

const QuantitySelector = ({ quantity, setQuantity, small = false }) => {
  const increase = () => {
    setQuantity((count) => count + 1)
  }

  const decrease = () => {
    quantity !== 0 && setQuantity((count) => count - 1)
  }

  const classes = [style.quantity]
  small && classes.push(style[`quantity--small`])

  return (
    <div className={[...classes].join(" ")}>
      <div className={style.quantity__button}>
        <button onClick={decrease}>
          <MinusIcon />
        </button>
      </div>
      <div className={style.quantity__qty}>
        <p>{quantity}</p>
      </div>
      <div className={style.quantity__button}>
        <button onClick={increase}>
          <PlusIcon />
        </button>
      </div>
    </div>
  )
}

export default QuantitySelector

const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <g
      id="Group_12141"
      data-name="Group 12141"
      fill="none"
      stroke="#591934"
      stroke-width="2"
    >
      <path
        id="Line_503"
        data-name="Line 503"
        transform="translate(0 8)"
        d="M0 0h16"
      />
      <path
        id="Line_504"
        data-name="Line 504"
        transform="rotate(90 4 4)"
        d="M0 0h16"
      />
    </g>
  </svg>
)

const MinusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 4">
    <path
      id="Line_502"
      data-name="Line 502"
      fill="none"
      stroke="#591934"
      stroke-width="4"
      d="M0 1h20"
    />
  </svg>
)
