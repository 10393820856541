import React, { useState } from "react"
import style from "./information-block.mod.scss"
import Rodal from "rodal"
import "rodal/lib/rodal.css"

const InformationBlock = (props) => {
  const [modalOpen, setModalOpen] = useState(false)

  const handleModal = () => {
    setModalOpen(true)
  }

  const handleOnClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <div className={style.modal}>
        {props.hasMultipleWines && (
          <>
            <p className={style.modal__text}>
              Why do I have multiple shopping carts?
            </p>
            <button className={style.modal__button} onClick={handleModal}>
              Click here to learn more about our checkout process.
            </button>
          </>
        )}
        <Rodal
          closeOnEsc={true}
          showCloseButton={true}
          closeMaskOnClick={true}
          visible={modalOpen}
          onClose={handleOnClose}
        >
          <div className={style.modal__content}>
            <InformationContent {...props} />
          </div>
        </Rodal>
      </div>
      <dv className={style.modal__desktop}>
        <InformationContent {...props} />
      </dv>
    </>
  )
}

export default InformationBlock

export const InformationContent = ({
  cartPageTitle,
  cartPageIntro,
  stepOneTitle,
  stepOneText,
  stepTwoTitle,
  stepTwoText,
  stepThreeTitle,
  stepThreeText,
  hasMultipleWines,
}) => {
  return (
    <div>
      <div className={style.information}>
        {hasMultipleWines && (
          <div className={style.information__intro}>
            <p> {cartPageTitle}</p>
            <div dangerouslySetInnerHTML={{ __html: cartPageIntro }} />
          </div>
        )}

        <div className={style.information__stepper}>
          <div className={style.information__wrapper}>
            <p className={style.information__wrapper__number}>1</p>
            <div class={style.information__text_wrap}>
              <p className={style.information__title}>{stepOneTitle}</p>
              <p className={style.information__text}>{stepOneText}</p>
            </div>
          </div>
          <div className={style.information__wrapper}>
            <p className={style.information__wrapper__number}>2</p>
            <div class={style.information__text_wrap}>
              <p className={style.information__title}>{stepTwoTitle}</p>
              <p className={style.information__text}>{stepTwoText}</p>
            </div>
          </div>
          <div className={style.information__wrapper}>
            <p className={style.information__wrapper__number}>3</p>
            <div class={style.information__text_wrap}>
              <p className={style.information__title}>{stepThreeTitle}</p>
              <p className={style.information__text}>{stepThreeText}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
