import React, { useState, useEffect, useContext } from "react"
import { getCart } from "../services/cart"
import Layout from "../components/global/layout"
import Container from "../components/container/container"
import CartItem from "../components/cart/cart-item"
import SimpleIntro from "../components/global/simple-intro"
import { toast } from "react-toastify"
import { AuthContext } from "../context/AuthContext"
import { eventViewCart } from "../services/gtm-events"
import InformationBlock from "../components/cart/information-block"
import { useStaticQuery, graphql } from "gatsby"

const CartPage = ({ component: Component, location, ...rest }) => {
  const { handleContextGetCartCount, contextCartCount, contextBottleCount } =
    useContext(AuthContext)

  const data = useStaticQuery(graphql`
    query {
      infoBlock: allDatoCmsMiscContent {
        nodes {
          cartPageTitle
          cartPageIntro
          stepOneTitle
          stepOneText
          stepTwoTitle
          stepTwoText
          stepThreeTitle
          stepThreeText
        }
      }
    }
  `)

  const [cartItems, setCartItems] = useState([])
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(false)
  const [cartItemsNumber, setCartItemsNumber] = useState(0)
  const [cartBottleNumber, setCartBottleNumber] = useState("0")
  const [cartLoading, setCartLoading] = useState(true)

  useEffect(() => {
    setCartItemsNumber(contextCartCount)
    setCartBottleNumber(contextBottleCount)
  }, [contextCartCount, contextBottleCount])

  const handleGetCart = () => {
    setCartLoading(true)
    getCart().then((res) => {
      if (res.status && res.data.result) {
        // console.log("getCart Response: ", res.data.result)
        setCartItems(res.data.result)
        eventViewCart(res.data.result)
      } else {
        if ((res.message = "Cart not Found.")) {
          toast.error("Your cart is empty")
        } else {
          toast.error(res.message)
        }
        setCartItems([])
      }
    })

    handleContextGetCartCount()
    setCartLoading(false)
  }

  useEffect(() => {
    handleGetCart()
  }, [])

  return (
    <Layout>
      <Container width={"medium"} gutters pb={2}>
        <SimpleIntro
          title={"My cart"}
          text={
            !cartLoading
              ? `You have ${cartItemsNumber} items in your cart (${cartBottleNumber} bottles)`
              : "Loading Cart...."
          }
        />
        <InformationBlock
          cartPageTitle={data.infoBlock.nodes[0].cartPageTitle}
          cartPageIntro={data.infoBlock.nodes[0].cartPageIntro}
          stepOneTitle={data.infoBlock.nodes[0].stepOneTitle}
          stepOneText={data.infoBlock.nodes[0].stepOneText}
          stepTwoTitle={data.infoBlock.nodes[0].stepTwoTitle}
          stepTwoText={data.infoBlock.nodes[0].stepTwoText}
          stepThreeTitle={data.infoBlock.nodes[0].stepThreeTitle}
          stepThreeText={data.infoBlock.nodes[0].stepThreeText}
          hasMultipleWines={cartItems.length > 1 ? true : false}
        />

        {cartItems.map((item) => (
          <CartItem handleGetCart={handleGetCart} {...item} />
        ))}
      </Container>
    </Layout>
  )
}
export default CartPage
